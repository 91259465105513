import Vue from 'vue'
import App from './App.vue'
import router from './router/router' //导入路由对象
import VConsole from 'vconsole'

let vConsole = new VConsole()

//引入阿里图标
import "../src/assets/icon/iconfont.css";

import Vant from 'vant';


import 'vant/lib/index.css';
import {getToken, remove} from "@/utils/AuthUtils";
import MessageUtils from "@/utils/MessageUtils";

Vue.use(Vant);
Vue.use(vConsole);
Vue.config.productionTip = false


router.beforeEach(async (to, from, next) => {
    document.title = to.meta.title
    let token = getToken();
    if (!token) {
        if (to.path === "/login" || to.path === "/" || to.path === "/loading" || to.path === '/register') {
            console.log(to.path)
            next();
        } else {
            remove()
            MessageUtils.simpleness("对不起，您未登录请先登录")
            next({path: '/login'})
        }
        //跳到登录
    } else {
        next();
    }
})

new Vue({
    render: h => h(App),
    router,
}).$mount('#app')
